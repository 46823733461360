.App-content {
  text-align: center;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.App-lg-width {
  text-align: center;
  max-width: 2400px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

@media (min-width: 1600px) {
  body {
    background-color: white;
  }
}

.App-content {
  min-height: calc(100vh - 60px) !important;
  background-color: white;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  background: "#fff";
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fff;
  font-size: 16px;
  display: flex;
  height: 100%;
  padding: 0 25px;
  align-items: center;
  text-decoration: none;
}

.App-link.active {
  background-color: #162a46 !important;
}

.App-link.active {
  background-color: #050505;
}

.body {
  width: 100%;
  padding: auto;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  color: #000;
  font-size: 30px;
}

.center {
  padding: 0 5%;
  margin: 3%;
  flex-direction: column;
}

/* DateInput style */
.dp-full-width {
  width: 100%;
}

.sign-in {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur-modal {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px 40px 20px 40px;
  position: absolute;
  z-index: 9999;
  bottom: 15%;
  text-align: center;
  left: 0;
  right: 0;
  margin-right: 40px;
  margin-left: 40px;
}

.case-options-mobile {display: none;}

.edit-modal ::-webkit-scrollbar {
  display: none;
}

.icon-wrapper {
  display: flex;
  margin: 29px 0;
  align-items: center;
  width: 82%;
  margin-right: auto;
  margin-left: auto;
}

.filter-icon {
  margin-left: 8px !important;
  position: absolute !important;
  top: 0.85rem !important;
}

.ellipses-icon svg {
  width: 8px !important;
  color: #c4c4c4 !important;
}

.icon-wrapper span {
  width: 73%;
  text-align: left;
  margin-left: 32px;
}

.edit-icon,
.trash-icon {
  font-size: 0.9rem;
  color: #498cc2;
  border-radius: 5px;
  padding: 4px;
  border: 1px solid #d3d3d3;
  margin: 0;
  margin-right: 0;
}

.smiley-image,
.communication-image,
.handshake-image {
  height: 50px;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.checkmark {
  color: #32ab5b;
}

.passwordValidationText {
  display: flex;
  text-align: left;
}

.passwordValidationText p {
  margin: 0rem 0 0 0.5rem;
}

.close {
  position: absolute;
  top: 0%;
  right: 5%;
  margin: 25px 0 !important;
  color: #000;
  cursor: pointer;
}

/* TABLE*/
.table-wrapper {
  width: 100%;
  /* calculate height of table */
  height: calc(100vh - 105px - 60px) !important;
}

.team-table-wrapper {
  width: 100%;
}

.table,
.team-table-wrapper {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.table {
  overflow-y: hidden;
}

div.ReactVirtualized__Table__headerRow {
  text-transform: capitalize !important;
  color: #bababa !important;
}

svg.ReactVirtualized__Table__sortableHeaderIcon path,
.ReactVirtualized__Table__headerColumn:last-child {
  visibility: hidden !important;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  align-items: center;
}

svg.ReactVirtualized__Table__sortableHeaderIcon {
  background: url("./assets/chevron-down.svg") no-repeat !important;
  margin-left: 4px;
}

.ReactVirtualized__Table__headerRow {
  font-weight: 600 !important;
}

.ReactVirtualized__Table__row {
  font-weight: 500;
}

.headerRow,
.evenRow,
.oddRow {
  border-bottom: 1px solid #d3d3d3;
}

.table,
.table-row {
  text-align: left;
  padding: 0 !important;
}

.table-heading > div {
  margin: 11px 16px !important;
}

.table-row .MuiGrid-item {
  margin: 25px 16px;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  overflow: auto !important;
}

/* Buttons */
.btn {
  color: #fff !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 5px 25px !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: bold !important;
  min-width: 180px !important;
  height: 40px !important;
}

.view-case {
  text-transform: none !important;
  border: 1px solid #5ca1f2 !important;
  border-radius: 25px !important;
  color: #5ca1f2 !important;
  transition: 0.3s !important;
  width: 158px !important;
  height: 41px !important;
  font-weight: 500 !important;
}

.view-case:hover {
  background-color: #5ca1f2 !important;
  color: white !important;
}

.document-button {
  width: initial !important;
  height: initial !important;
  margin: 25px !important;
}

.btn.blue {
  background: #6db0eb;
}

.btn.green {
  background: #2a528c;
  box-shadow: -4px 4px 25px rgba(0, 0, 0, 0.25);
}

.btn.light-blue {
  background: #498cc2;
  border-radius: 25px !important;
}

.btn.clear {
  border: 1px solid black;
  color: #000 !important;
  transition: "all 0.2s linear";
  border-radius: 25px !important;
}

.btn.account-btn {
  width: 242px !important;
  margin: 6% 1% !important;
}

.btn.clear:hover {
  background-color: #dfdfdf !important;
}

.auth-btn,
.submit-btn {
  border-radius: 25px !important;
  width: 260px !important;
  height: 50px !important;
  align-self: center !important;
}

.auth-btn,
.submit-btn {
  font-weight: bold !important;
  text-transform: none !important;
}

.submit-btn {
  margin: 0 !important;
}

.sign-up-btn {
  margin: 16px 0 !important;
  text-transform: lowercase;
  box-shadow: 0 3px 12px rgb(0 0 0 / 0.5);
  color: #fff !important;
  font-weight: bold !important;
  width: 100%;
  height: 50px !important;
  border-radius: 20px !important;
  background-color: #2a528c !important;
}

button:disabled {
  opacity: 0.4;
}

button:disabled:hover {
  cursor: auto;
}

.resend-btn,
.submit-btn {
  width: 260px;
  height: 50px;
  margin: 0 auto !important;
}

.search {
  border-radius: 20px !important;
  font-size: 12px !important;
  width: 295px !important;
  color: #000 !important;
  border: 1px solid #c4c4c4 !important;
  height: 40px !important;
}

.search input {
  padding: 0 !important;
  color: #000 !important;
}

.search input::placeholder {
  color: #000 !important;
}

.search:focus {
  outline: none;
}

.search,
.search-icon {
  color: #5c5c5c;
}

.btn:disabled {
  opacity: 0.2;
}

.add-btn {
  align-self: center !important;
  margin: 40px auto 16px !important;
  border-radius: 25px !important;
  cursor: pointer;
  width: 242px !important;
  height: 50px !important;
}

.add-btn.add-case {
  margin-top: 0 !important;
  border-radius: 25px !important;
  cursor: pointer;
  width: 242px !important;
  height: 50px !important;
}

.cancel-btn.add-case {
  border-radius: 25px !important;
  width: 242px !important;
  height: 50px !important;
}

/* MUI Tabs */
.add-case-tabs.MuiTabs-root {
  height: 58px;
}

.add-case-tabs .MuiTabs-centered {
  justify-content: space-between !important;
}

.add-case-tabs .MuiTabs-indicator {
  height: 1.5px !important;
}

.add-case-tabs .MuiTab-root {
  padding: 0 !important;
  height: 10px !important;
  letter-spacing: 0 !important;
}

.modal {
  display: flex;
  text-align: center;
  padding: auto;
  min-height: 100vh;
}

.modal-content {
  margin: auto;
  background: #fff;
  width: 578px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: block;
  padding: 56px 90px 40px;
}

.modal-content.edit-labels {
  padding: 40px 24px;
}

.modal-form {
  padding: 0px 24px;
}

.modal-scroll {
  max-height: 40vh;
  overflow-x: auto;
  padding: 0px 17px;
  margin-top: 24px;
}

.modal-header {
  font-weight: 700;
  margin: 16px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 500px;
    padding: 40px 80px;
  }
}

@media (max-width: 524px) {
  .modal-content {
    width: 95% !important;
    margin: auto;
    padding: 40px 32px;
  }
}

.tabs .MuiTab-root {
  padding: 0;
}

.modal-btn {
  background: #498cc2 !important;
  border-radius: 25px !important;
  color: #fff !important;
  padding: 2.5% 9% !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.carousel-modal-btn {
  margin-top: 32px !important;
}

.btn.case {
  background: #498cc2;
  border-radius: 25px !important;
  min-width: 200px !important;
  margin: 32px auto !important;
}

.btn.e-sign-btn {
  margin-right: 0 !important;
  min-width: 245px !important;
}

/* react-places-autocomplete */
.location-search-input .MuiInputBase-input.MuiInput-input {
  font-size: 14px !important;
  font-weight: 600;
}

.providers
  div.MuiFormControl-root.MuiTextField-root.location-search-input.MuiFormControl-fullWidth
  div {
  margin: 3.6% 0 !important;
}

.firm div div {
  margin-bottom: 0 !important;
}

.firm div div div div input:disabled {
  color: #5c5c5c !important;
  opacity: 0.6;
}

.createFirm div div {
  margin-bottom: 2px !important;
}

.updateFirm div.MuiFormControl-root,
.createFirmWrapper div.MuiFormControl-root {
  margin: 0 !important;
}

.providers
  div.MuiFormControl-root.MuiTextField-root.location-search-input.MuiFormControl-fullWidth
  div
  input::placeholder {
  opacity: 1;
}

div.MuiFormControl-root.MuiTextField-root.location-search-input.MuiFormControl-fullWidth
  div
  input::placeholder {
  color: #5c5c5c;
}

.clickableRow:hover {
  background-color: blue;
}

a {
  text-decoration: none;
  color: inherit;
}

.heading {
  color: #000 !important;
  text-align: left;
}

input.file-upload::-webkit-file-upload-button {
  cursor: pointer;
  color: #000;
  border: solid 1px #000;
  background-color: transparent;
  padding: 2.8% 8%;
  border-radius: 5px;
  margin-right: 24px;
}

/* Material UI Custom Styles */
.MuiPaper-elevation8 {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 9px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiTabs-scroller {
  margin: 10px auto !important;
}

.eSignRequests .MuiTabs-scroller.MuiTabs-fixed {
  margin: 0 !important;
}

.MuiGrid-container {
  position: relative !important;
}

.MuiAccordionSummary-expandIcon {
  position: absolute !important;
  top: 0.8rem !important;
  right: 1rem !important;
}

.MuiButton-root:hover {
  background-color: #6db0eb !important;
  opacity: 0.8;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

.MuiInputBase-inputAdornedStart {
  font-size: 16px !important;
  width: 400 !important;
  height: 21px !important;
}

.MuiInputBase-inputAdornedStart::placeholder {
  color: #5c5c5c !important;
}

.MuiIconButton-edgeEnd {
  margin: 0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #498cc2 !important;
}

.Mui-focused,
legend.MuiFormLabel-root {
  color: #5c5c5c !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #000 !important;
}

.MuiTabs-indicator {
  color: #000 !important;
}

span.MuiButtonBase-root {
  padding: 0 !important;
  padding-right: 7px !important;
}

.MuiSvgIcon-root {
  width: 21px !important;
  height: 21px !important;
}

.case-tabs .MuiTabs-scroller {
  margin: 0px !important;
}

.case-tabs .MuiTab-wrapper span {
  width: 100% !important;
  text-align: left;
  border-radius: 25px;
  padding: 7px 15px !important;
}

.tabs-wrapper .MuiTab-wrapper {
  flex-direction: row;
  justify-content: flex-start;
}

.tabs-wrapper .MuiAvatar-root.MuiAvatar-circular {
  border-radius: 0 !important;
}

.tabs-wrapper .MuiTab-labelIcon {
  min-height: 60px !important;
}

.tabs-wrapper svg {
  margin: 0 !important;
  margin-right: 8px !important;
}

.paper .MuiPaper-elevation1 {
  box-shadow: 0px 4px 4px 0px #00000014;
  border: 2px solid #f9f9f9;
  padding: 0 !important;
}

/* React Toastify */
.Toastify__toast-theme--light {
  font-weight: 700;
  background: #fbf7ea !important;
}

/* Stripe */
.stripe iframe {
  height: 100% !important;
}

#card-number-element .InputContainer .InputElement {
  border: 2px solid red !important;
}

.StripeElement {
  border: 1px solid #eee;
}

.StripeElement--invalid {
  border: 1px solid red;
}

/* Transitions */
.my-node-enter {
  opacity: 0;
  /* transform: scale(1.1); */
}

.my-node-enter-active {
  opacity: 1;
  /* transform: scale(1); */
  transition: opacity 700ms, transform 700ms;
}

.my-node-exit {
  opacity: 1;
  /* transform: scale(1); */
}

.my-node-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 700ms, transform 700ms;
}

/* Fonts */
.sf-pro {
  font-family: "SFProDisplay-Regular";
}

/* .MuiButton-root */

/* Spinning Icons */

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.MuiTouchRipple-root {
  background-color: transparent !important;
}

.sweet-loading img {
  animation-name: pulse_animation;
  animation-duration: 1250ms;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

body .client-details {
  height: 100vh;
  overflow: hidden !important;
}

@keyframes pulse_animation {
  0% {
    transform: scale(1, 1);
  }

  15% {
    transform: scale(1.2, 1.2);
  }

  30% {
    transform: scale(1, 1);
  }

  45% {
    transform: scale(1.1, 1.1);
  }

  60% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* React Drag and Drop */
.choose-file-input {
  padding: 7% 12%;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
  margin-right: 0.6rem;
}

.choose-file-text {
  margin: 0;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.drop-file-section {
  min-height: 120px;
  max-width: 100%;
  border: 2px dashed rgb(194, 194, 194);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.drop-file-section div:first-child {
  margin: 8px auto;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-file-section p {
  margin: 8px auto;
}

.autocompleteinput: {
  text-transform: capitalize;
}

@media (max-width: 1169px) {
  .modal-btn {
    padding: 2% 5% !important;
    font-size: 14px !important;
  }

  .carousel-modal-btn {
    margin-top: 24px !important;
  }
}

@media (max-width: 1199px) {
  .btn.light-blue:not(.add-btn),
  .heading {
    margin: 25px !important;
  }
}

@media (max-width: 1200px) {
  .table-wrapper,
  .team-table-wrapper {
    /* calculate height of table */
    height: calc(100vh - 154px - 60px) !important;
  }

  .table-wrapper {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .team-table {
    width: 1150px;
    margin: auto;
  }
}

@media (max-width: 895px) {
  .btn.case:not(.e-sign-btn) {
    min-width: 100% !important;
    margin: 0 auto 32px !important;
  }

  .btn.case.e-sign-btn {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 8px 0 !important;
  }
}

@media (max-width: 768px) {
  .table-wrapper,
  .team-table-wrapper {
    /* calculate height of table */
    height: calc(100vh - 208px - 60px) !important;
  }

  .edit-icon {
    visibility: visible !important;
  }

  .MuiInput-input,
  .btn.btn.light-blue:not(.add-btn) {
    margin: 5px auto !important;
  }

  .main {
    flex-direction: column !important;
  }

  .main div:last-child {
    width: 100% !important;
  }

  .heading {
    margin: 0 !important;
    margin-bottom: 0.6rem !important;
  }

  .search {
    width: 90% !important;
    margin: 0 !important;
    margin-bottom: 0.6rem !important;
  }

  .btn:not(.add-btn) {
    width: 60% !important;
  }

  .App-link {
    display: none !important;
  }

  .sign-in {
    margin: 0 16px !important;
  }

  .btn.account-btn {
    min-width: 50px !important;
  }

  .auto-height-mobile .MuiBox-root {
    height: auto !important;
  }

  .btn.e-sign-btn {
    margin-bottom: 0 !important;
  }

  .modal-btn {
    font-size: 14px !important;
  }

  .case-options {display: none}
  .case-options-mobile {display: initial}
}

@media (max-width: 767px) {
  .tabs-wrapper svg {
    margin-right: 4px !important;
  }

  .MuiInputBase-root.MuiInput-root.search, .MuiButtonBase-root {
    max-width: 60%;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.makeStyles-optionButton-16 {
    max-width: initial !important;
  }
}

@media (max-width: 414px) {
  .case-timeline-btn {
    width: 100% !important;
  }

  .tabs-wrapper .MuiTab-wrapper {
    flex-direction: column;
    align-content: start !important;
    inline-size: 65px;
  }

  .choose-file-text {
    margin-left: 2rem;
  }

  .drop-file-section {
    margin-right: 2rem;
  }

  .tooltip .tooltiptext {
    max-width: 120px;
    margin-left: -60px;
  }

  .add-btn {
    width: 100% !important;
  }

  .modal-form {
    padding: 0;
  }
}

@media (max-width: 200px) {
  .btn {
    width: 100% !important;
  }
}

.submit-btn {
  margin: 0 !important;
}

.case-timeline-btn {
  height: 40px !important;
  margin: 2% 0 7% 0 !important;
}
