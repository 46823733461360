body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SFProDisplay-Regular";
  src: local("SFProDisplay-Regular"),
    url("./fonts/SFProDisplay-Regular.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SFProDisplay-Medium";
  src: local("SFProDisplay-Medium"),
    url("./fonts/SFProDisplay-Medium.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SFProDisplay-Bold";
  src: local("SFProDisplay-Bold"),
    url("./fonts/SFProDisplay-Bold.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SFProDisplay-Light";
  src: local("SFProDisplay-Light"),
    url("./fonts/SFProDisplay-Light.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SFProDisplay-Black";
  src: local("SFProDisplay-Black"),
    url("./fonts/SFProDisplay-Black.ttf") format("opentype");
  font-weight: bold;
}
